<template>
  <div>
    <b-row>
      <b-col>
        <b-alert
          :show="!validLuxonDate"
          variant="warning"
          class="p-1"
        >
          Invalid Date ( Showing data of Today,  {{ dateLuxon.toFormat('dd-MM-yyyy') }} )
        </b-alert>

        <!--    {{ chartAxisData }}-->

        <b-card>
          <template #header>
            <h4 class="mr-auto mb-0">

              <template v-if="$route.params.type ==='day'">
                DAY REPORT FOR DATE <span class="text-uppercase">{{ dateLuxon.toFormat('dd MMM yyyy') }}</span>
              </template>
              <template v-if="$route.params.type ==='week'">
                LAST 7 DAYS <span class="text-uppercase">({{ dateLuxon.plus({ weeks: -1 }).plus({ days: 1 }).toFormat('dd MMM yyyy') }} - {{ dateLuxon.toFormat('dd MMM yyyy') }})</span>
              </template>
              <template v-if="$route.params.type ==='month'">
                MONTHLY REPORT FOR  <span class="text-uppercase">{{ dateLuxon.toFormat('MMMM yyyy') }}</span>
              </template>
              <template v-if="$route.params.type ==='year'">
                YEARLY REPORT FOR <span class="text-uppercase">{{ dateLuxon.toFormat('yyyy') }}</span>
              </template>
            </h4>

            <div>
              <b-input-group>
                <flat-pickr
                  v-model="selectedFlatPickrDate"
                  style="width: 10px"
                  class="invisible"
                  :config="datePckr"
                  @on-change="changedDate($route.params.type, selectedFlatPickrDate)"
                />
                {{ selectedFlatPickrDate }}
                <flat-pickr
                  v-if="$route.params.type === 'month'"
                  v-model="selectedFlatPickrDate"
                  style="width: 10px"
                  class="invisible"
                  :config="datePckrMonth"
                  @on-change="changedDate($route.params.type, selectedFlatPickrDate)"
                />

                <b-button
                  v-if="$route.params.type === 'day'"
                  style="right: 0; width: 150px;"
                  size="sm"
                  variant="primary"
                  class="position-absolute"
                  data-toggle
                >
                  <template v-if="!selectedFlatPickrDate">
                    {{ $route.params.selectedDate }}
                  </template>
                  <template v-if="selectedFlatPickrDate">
                    {{ selectedFlatPickrDate }}
                  </template>
                </b-button>
                <b-button
                  v-if="$route.params.type === 'month'"
                  variant="primary"
                  style="right: 0; width: 150px;"
                  size="sm"
                  class="position-absolute px-2"
                  data-toggle
                >
                  <template v-if="!selectedFlatPickrDate">
                    {{ dateLuxon.toFormat('MMMM yyyy') }}
                  </template>
                  <template v-if="selectedFlatPickrDate">
                    {{ selectedFlatPickrDate }}
                  </template>
                </b-button>

                <b-dropdown
                  v-if="$route.params.type === 'year'"
                  size="sm"
                  :text="dateLuxon.toFormat('yyyy')"
                  variant="primary"
                  right
                >
                  <template v-for="(selectedYear, index) in ['2020','2021','2022']">
                    <b-dropdown-item
                      :key="index"
                      @click="getChartsData('year', '01-01-'+selectedYear)"
                    >
                      {{ selectedYear }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </b-input-group>
            </div>

          </template>
          <chartjs-component-bar-chart
            class="mt-3"
            :height="600"
            :chart-data="chartAxisData"
            :options="chartjsData.latestBarChart.options"
          />
        </b-card>

        <b-card no-body>
          <template #header>
            <h4 class="mr-auto mb-0">
              TRANSACTION DETAILS
            </h4>
          </template>
          <b-table
            :no-local-sorting="true"
            striped
            small
            bordered
            responsive
            show-empty
            hover
            :items="getTableData"
            :fields="getTableFields"
          >
            <template #cell(y_axis)="row">
              <vue-numeric
                read-only
                :precision="2"
                :currency="globalCurrency"
                separator=","
                :value="row.item.y_axis"
              />
            </template>
            <template #cell(created_at)="row" />
          </b-table>
        </b-card>

        <!--        <pre v-if="debugMode">{{ getChartData }}</pre>-->
      </b-col>
      <b-col class="d-none">
        <b-card>
          <template #header>
            YEARLY REPORT
          </template>

          <div>
            <b-button-group
              class="w-100"
              size="md"
            >
              <template v-for="(year,index) in ['2020','2021','2022','2023']">
                <b-button
                  :key="index"
                  :pressed.sync="parseInt(year) === dateLuxon.year"
                  :class="[{'btn-light': parseInt(year) === dateLuxon.year}]"
                  variant="primary"
                  @click="getChartsData('year', year)"
                >
                  {{ year }}
                </b-button>
              </template>
            </b-button-group>

          </div>
        </b-card>
        <b-card-actions action-collapse>

          <div>
            <b-button-group
              class="w-100"
              size="md"
            >
              <template v-for="(year,index) in ['2020','2021','2022','2023']">
                <b-button
                  :key="index"
                  :pressed.sync="parseInt(year) === dateLuxon.year"
                  :class="[{'btn-light': parseInt(year) === dateLuxon.year}]"
                  variant="primary"
                  @click="getChartsData('year', year)"
                >
                  {{ year }}
                </b-button>
              </template>
            </b-button-group>

          </div>
        </b-card-actions>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { BCardActions } from '@core/components/b-card-actions'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import humaniseMixin from '@/common/humanise.mixin'
import store from '@/store'
import chartjsData from '@/views/admin/temp/dashboardComponents/chartjsData'
import ChartjsComponentBarChart from '@/views/admin/temp/dashboardComponents/ChartjsComponentBarChart.vue'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  components: {
    ChartjsComponentBarChart, BCardActions, flatPickr, monthSelectPlugin,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      chartjsData,
      chartData: null,
      selectedMonth: null,
      selectedDay: null,
      selectedYear: null,
      selectedFlatPickrDate: null,
      datePckr: {
        altInput: false,
        dateFormat: 'd-m-Y',
        wrap: true,
      },
      datePckrMonth: {
        altInput: false,
        dateFormat: 'd-m-Y',
        wrap: true,
        plugins: [
          new monthSelectPlugin({
            shorthand: true, // defaults to false
            dateFormat: 'd-m-Y', // defaults to "F Y"
            altFormat: 'M Y', // defaults to "F Y"
            theme: 'light', // defaults to "light"
          }),
        ],
      },

      getTableFields: [
        {
          key: 'x_axis', label: 'Date/Time', sortable: false, visible: true,
        },
        {
          key: 'y_axis', label: 'Transaction Amount', sortable: false, visible: true, class: 'text-right',
        },
        {
          key: 'count', label: 'Transaction Count', sortable: false, visible: true, class: 'text-right',
        },
        {
          key: 'action', label: 'Action', sortable: false, visible: false, class: 'text-right',
        },
      ],

    }
  },
  computed: {
    validLuxonDate() {
      const x = this.$route.params.selectedDate
      const validity = DateTime.fromFormat(x, 'dd-MM-yyyy').isValid
      return validity
    },
    dateLuxon() {
      const x = this.$route.params.selectedDate
      const validity = DateTime.fromFormat(x, 'dd-MM-yyyy').isValid
      if (validity) {
        return DateTime.fromFormat(x, 'dd-MM-yyyy')
      }
      return DateTime.now()
    },
    getChartData() {
      const list = this.$store.getters['dashboard/activeChartData'](this.$route.params.type)
      if (list) {
        return list
      }
      return []
    },
    getTableData() {
      const list = this.$store.getters['dashboard/activeChartData'](this.$route.params.type)
      let tableKey

      if (list) {
        if (this.$route.params.type === 'day') {
          tableKey = 'today-graph'
        }
        if (this.$route.params.type === 'month') {
          tableKey = 'monthly-graph'
        }
        if (this.$route.params.type === 'year') {
          tableKey = 'monthly-graph'
        }
        if (this.$route.params.type === 'week') {
          tableKey = 'monthly-graph'
        }
        return list[tableKey]
      }
      return []
    },
    chartAxisData() {
      console.log('type', this.$route.params.type)
      const list = this.$store.getters['dashboard/activeChartData'](this.$route.params.type)
      let chartKey
      if (this.$route.params.type === 'day') {
        chartKey = 'today-graph'
      }
      if (this.$route.params.type === 'month') {
        chartKey = 'monthly-graph'
      }
      if (this.$route.params.type === 'year') {
        chartKey = 'monthly-graph'
      }
      if (this.$route.params.type === 'week') {
        chartKey = 'monthly-graph'
      }
      console.log('---->', list)
      if (list) {
        return {
          labels: this.processLabel(this.getChartData[chartKey]),
          datasets: [{
            data: this.processCount(this.getChartData[chartKey]), backgroundColor: '#28dac6', borderColor: 'transparent', _meta: {},
          }],
        }
      }
      return {
        labels: [],
        datasets: [{
          data: [], backgroundColor: '#28dac6', borderColor: 'transparent', _meta: {},
        }],
      }
      // return series
    },
  },
  async mounted() {
    await store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)

    const breadcrumbUpdatePayload = [
      { title: 'Account' },
      { title: 'Reports' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    if (!this.validLuxonDate || !['day', 'month', 'week', 'year'].includes(this.$route.params.type)) {
      await this.$router.push({
        name: 'administrator-reports-viewer',
        params: { type: 'day', selectedDate: DateTime.now().toFormat('dd-MM-yyyy') },
      })
    }

    let payload = {
      skip: 0,
      limit: 50,
    }

    // await this.$store.dispatch('dashboard/getMerchantDashboard', payload)

    // this.getChartsData(this.$route.params.type)

    if (this.$route.params.type === 'day') {
      payload = {
        skip: 0,
        accountId: this.$route.params.id,
        limit: 1000,
        date: this.dateLuxon.toFormat('dd-MM-yyyy'),
      }
      await this.$store.dispatch('dashboard/getDashboardGenerateReportDay', payload)
    }
    if (this.$route.params.type === 'week') {
      // alert(this.$route.params.type)
      payload = {
        skip: 0,
        accountId: this.$route.params.id,
        limit: 1000,
        date: this.dateLuxon.toFormat('dd-MM-yyyy'),
      }
      await this.$store.dispatch('dashboard/getDashboardGenerateReportLastWeek', payload)
    }
    if (this.$route.params.type === 'month') {
      payload = {
        skip: 0,
        accountId: this.$route.params.id,
        limit: 1000,
        month: this.dateLuxon.month,
      }
      await this.$store.dispatch('dashboard/getDashboardGenerateReportMonth', payload)
    }
    if (this.$route.params.type === 'year') {
      payload = {
        skip: 0,
        accountId: this.$route.params.id,
        limit: 1000,
        year: this.dateLuxon.year,
      }
      await this.$store.dispatch('dashboard/getDashboardGenerateReportYear', payload)
    }
  },
  methods: {
    changedDate(type, selectedDate) {
      this.getChartsData(type, selectedDate)
    },
    processLabel(obj) {
      const x = []
      if (obj) {
        obj.forEach(item => {
          x.push(item.x_axis)
        })
        console.log(x)
        return x
      }
      return []
    },
    processCount(obj) {
      const x = []
      if (obj) {
        obj.forEach(item => {
          x.push(item.y_axis)
        })
        console.log(x)
        return x
      }
      return []
    },
    getChartsData(type, date) {
      const x = date
      const validity = DateTime.fromFormat(x, 'dd-MM-yyyy').isValid

      // alert(date)
      if (validity) {
        this.$router.push({
          name: 'administration-reports-viewer',
          params: { type, selectedDate: DateTime.fromFormat(x, 'dd-MM-yyyy').toFormat('dd-MM-yyyy') },
        })
      }
    },
  },
}
</script>

<style>

</style>
